footer {
    .footer__wrapper {
        @include block-pos;
        padding: 59.5px 0;
        @include flex-params (row, space-between, center);

        nav {
            ul {
                display: flex;

                li {
                    margin-right: 30px;

                    a {
                        @include font-params (400, 16px, 150%, 0.05em);

                        &:hover {
                            color: #DB0BC0;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        p {
            @include font-params (400, 16px, 150%, 0.05em);
            color: $link_color;
        }
    }
}