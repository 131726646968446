@media only screen and (max-width: 849px) and (min-width: 311px) {
    .registration-type {
        padding-top: 60px;
        @include flex-params (column, flex-start, center);
        text-align: center;

        h2 {
            @include font-params (400, 30px, 130%, 0.0016em);
            font-family: 'Days One', sans-serif;
            color: #051951;
            margin-bottom: 10px;
        }

        p {
            @include font-params (400, 16px, 22px, 0.05em);
            color: #717D96;
            margin-bottom: 42px;

            a {
                @include font-params (800, 16px, 22px, 0.05em);
                color: #051951;
            }
        }

        >a {
            text-align: center;
            display: block;
            width: 270px;
            height: 44px;
            padding-top: 9.5px;
            border-radius: 10px;
            background-color: #FC8000;
            color: #fff;
            @include font-params (700, 18px, 25px, 0.05em);
            margin: auto;
            margin-bottom: 20px;

            &.owner {
                background-color: #fff;
                border: 2px solid #FC8000;
                color: #FC8000;
                margin-right: 0;
            }
        }

        .caption {
            @include font-params (400, 16px, 22px, 0.05em);
            color: #A0ABC0;

            a {
                color: #204BDB;
                @include font-params (400, 16px, 22px, 0.05em);
            }
        }
    }
}