.subscription {
    padding: 44px 0 58px;
    background: url('../../../../public/images/subscription/subscription-left-bg.png') no-repeat 0,
        url('../../../../public/images/subscription/subscription-right-bg.png') no-repeat 100%;
    background-size: contain;
    background-color: #f6ba1e;

    >div {
        display: grid;
        grid-template-columns: 411px 408px;
        grid-gap: 38px;
        width: 857px;
        margin: auto;
    }

    .subscription__container {
        background-color: #fff;
        padding: 34px 0;
        @include flex-params (column, flex-start, center);
        color: $link_color;
        border: 1px solid #CBD2E0;

        img {
            margin-bottom: 10px;
        }

        h3 {
            @include font-params (400, 40px, 51px, 0.016em);
            color: #FC8000;
            font-family: 'Days One', sans-serif;
        }

        p,
        li {
            @include font-params (400, 16px, 170%, 0.05em);
            margin-bottom: 10px;
        }

        ul {
            margin-bottom: 16px;

            li {
                line-height: 22px;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url('../../../../public/images/subscription/list-point.svg') no-repeat center;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }

            p {
                padding-left: 34px;
                font-weight: 800;
                line-height: 22px;

                span {
                    font-weight: 800;
                }
            }
        }

        button {
            width: 225px;
            height: 63px;
            background: #FC8000;
            border-radius: 10px;
            color: #fff;
            @include font-params (700, 18px, 25px, 0.05em);
        }
    }

}