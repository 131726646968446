main {
    .main__title-wrapper {
        background: url('../../../public/images/main/main-bg.png') no-repeat center;
        background-size: cover;

        .main__title {
            padding: 165.5px 0 187.5px;
            @include block-pos;
            color: $link_color;

            h1 {
                @include font-params (400, 48px, 61px, 0.016em);
                margin-bottom: 15px;
            }

            p {
                @include font-params (400, 24px, 33px, 0.05em);
                margin-bottom: 15px;
            }

            >div {
                width: 967px;
                position: relative;

                input {
                    width: 967px;
                    height: 63px;
                    border-radius: 10px;
                    border: 1px solid #204BDB;
                    padding-right: 148px;
                    padding-left: 18px;
                    @include font-params (700, 18px, 25px, 0.05em);

                    &::placeholder {
                        color: #A0ABC0;
                    }
                }

                button {
                    position: absolute;
                    right: 0;
                    width: 130px;
                    height: 63px;
                    background: #204BDB;
                    @include font-params (700, 18px, 25px, 0.05em);
                    color: #fff;
                    border-radius: 0 10px 10px 0;
                }
            }

            .active-search {
                background: #fff;
                border-radius: 10px 10px 0 0;
            }

            .search-results {
                position: absolute;
                max-height: 200px;
                width: 100%;
                background: #fff;
                overflow-y: scroll;
                border-radius: 0 0 10px 10px;

                &::-webkit-scrollbar {
                    width: 0;
                }

                a {
                    display: block;
                    padding: 10px;
                }
            }
        }
    }

    .main__fishing-places {
        padding: 43px 0 46px;
        @include block-pos;
        text-align: center;

        .fishing-places__title {
            @include flex-params (row, space-between, center);
            margin-bottom: 43px;

            >button {
                color: $link_color;
                font-family: 'Days One', sans-serif;
                @include font-params (400, 18px, 23px, 0.016em);
                text-decoration: underline;

                &.filter-button {
                    &.open {
                        position: relative;

                        &::after {
                            position: absolute;
                            content: '';
                            display: inline-block;
                            bottom: 5px;
                            right: -24px;
                            width: 14px;
                            height: 14px;
                            background: url('../../../public/images/main/filter-cloce-icon.svg') no-repeat center;
                        }
                    }
                }
            }

            h2 {
                @include font-params (400, 40px, 51px, 0.016em);
                color: $link_color;
                margin-right: 93.5px;
            }

            >div {
                display: flex;
                align-items: center;

                div {
                    display: flex;
                }

                .filter-arrow {
                    width: 24px;
                    height: 24px;
                    background: url('../../../public/images/main/filter-arrow.svg') no-repeat center;
                    margin-right: 10px;

                    &.down {
                        transform: rotate(180deg);
                    }
                }

                .main_filters {
                    position: relative;
                    border-radius: 10px 10px 0 0;
                    transition: all 0.5s;
                    padding: 0 5px;
                    min-width: 167px;

                    p {
                        cursor: pointer;
                        color: $link_color;
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 18px, 23px, 0.016em);
                        text-decoration: underline;
                    }

                    .disabled {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: 30px;
                        left: 0;
                        max-height: 0;
                        overflow: hidden;
                        background: #fff;
                        z-index: 50;
                        width: 100%;
                        text-align: left;
                        transition: all .5s;
                        padding: 0 5px;
                        border-radius: 0 0 10px 10px;

                        p {
                            max-height: 0;
                            overflow: hidden;
                            transition: all .5s;
                        }

                        &.active {
                            max-height: 300px;
                            box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.1);
                            padding-bottom: 5px;

                            p {
                                max-height: 25px;
                            }
                        }
                    }
                }
            }
        }

        .fishing-places__wrapper {
            width: 100%;
        }

        .fishing-places__list {
            text-align: start;
            @include flex-params (row, space-between, flex-start);

            .fishing-places__filter {
                display: none;
                padding: 16.5px 10px 25px 10px;
                border: 1px solid #CBD2E0;
                border-radius: 10px;
                margin-right: 20px;

                .list_quality {
                    position: fixed;
                    top: 0;
                }

                &.show {
                    display: block;

                    &.show>div *::-webkit-scrollbar {
                        width: 10px;
                        position: relative;
                    }

                    &.show>div *::-webkit-scrollbar-track {
                        background: transparent;
                    }

                    &.show>div *::-webkit-scrollbar-thumb {
                        background: #221F63;
                        border-radius: 20px;
                        padding-top: 10px;
                    }
                }

                .filter-category {
                    font-family: 'Days One', sans-serif;
                    color: #FC8000;
                    margin-bottom: 10px;
                    @include font-params (400, 18px, 23px, 0.016em);
                    width: 292px;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../../../public/images/main/filter-arrow-gold.svg') no-repeat center;
                    }
                }

                .filter-subcategory {
                    padding-left: 22px;
                    @include font-params (700, 18px, 25px, 0.016em);
                    color: $link_color;
                    margin-bottom: 10px;
                    position: relative;
                    width: 270px;
                    cursor: pointer;

                    &.show {

                        &.active {
                            color: #FC8000;

                            &.scroll {
                                width: 286px;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: -7px;
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    background: url('../../../public/images/main/filter-arrow-gold.svg') no-repeat center;
                                }

                                .subcategory__wrapper {
                                    overflow-y: scroll;
                                    max-height: 300px;
                                    overflow-x: hidden;
                                }
                            }
                        }

                        &.input {
                            display: flex;
                            align-items: flex-start;

                            &.range {
                                flex-direction: column;
                            }

                            label {
                                cursor: pointer;
                            }

                            input {
                                width: 15px;
                                height: 15px;
                                margin-top: 5px;
                                margin-right: 10px;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .subcategory__filter {
                            display: none;
                            color: #221F63;
                            @include font-params (400, 18px, 25px, 0.016em);
                            height: 40px;
                            padding-top: 10px;
                            position: relative;

                            &:last-child {
                                margin: 0;
                            }

                            input {
                                margin-right: 20px;
                                opacity: 0;
                                z-index: -1;
                            }

                            li {
                                margin-bottom: 10px;
                            }

                            label {
                                display: block;
                                cursor: pointer;
                                position: absolute;
                                top: 0;
                                left: 34px;
                                width: 100%;
                                height: 100%;
                                padding-top: 10px;
                            }

                            input+label::before {
                                content: '';
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                background: url('../../../public/images/icons/check-icon-default.png') no-repeat center;
                                left: -33px;
                                top: 10px;
                            }

                            input:checked+label::before {
                                background: url('../../../public/images/icons/check-icon-active.png') no-repeat center;
                            }

                            &.show {
                                display: block;
                            }
                        }

                        .range_inputs {
                            display: none;

                            p {
                                color: #221F63;
                                @include font-params (400, 18px, 25px, 0.016em);
                            }

                            &.show {
                                display: flex;
                                justify-content: space-between;
                            }

                            .css-1aznpnh-MuiSlider-root {
                                width: 166px !important;
                                color: #FC8000 !important;

                                .css-14pt78w-MuiSlider-rail {
                                    border: none !important;
                                    height: 1px;
                                    color: #221F63 !important;
                                }

                                .MuiSlider-track {
                                    border: none !important;
                                    height: 1px;
                                    color: #221F63 !important;
                                }

                                .css-eg0mwd-MuiSlider-thumb::after {
                                    width: 11px !important;
                                    height: 11px !important;
                                    background: #FC8000 !important;
                                }

                                .MuiSlider-thumb {
                                    input {
                                        width: 11px !important;
                                        height: 11px !important;
                                        background: #FC8000 !important;
                                    }
                                }

                                .css-eg0mwd-MuiSlider-thumb {
                                    width: 11px !important;
                                    height: 11px !important;
                                }

                                .css-eg0mwd-MuiSlider-thumb:before {
                                    width: 11px !important;
                                    height: 11px !important;
                                }
                            }
                        }
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: -22px;
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../../../public/images/main/filter-arrow.svg') no-repeat center;
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .show-more {
            width: 225px;
            height: 63px;
            background: #204BDB;
            border-radius: 10px;
            color: #fff;
            @include font-params (700, 18px, 25px, 0.05em);
        }
    }

}

.ymaps-2-1-79-image-with-content {
    z-index: 5 !important;
    position: absolute;

    .placemark__container {
        display: flex;
        width: max-content;
        background-color: #fff;
        border-radius: 68px 5px 5px 68px;

        .placemark__content {
            height: 68px;
            min-width: 180px;
            padding-right: 10px;

            h2 {
                @include font-params (400, 20px, 25px, 0.016em);
                white-space: nowrap;
                margin: 0;
            }

            >div {
                @include flex-params (row, space-between, baseline);
                margin-bottom: 10px;

                p {
                    @include font-params (900, 16px, 22px, 0.05em);
                    color: #221F63;

                    &:first-child {
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background: url('../../../public/images/main/icon-likes.svg') no-repeat center;
                            vertical-align: middle;
                        }
                    }

                    &:last-child {
                        @include font-params (900, 24px, 33px, 0.05em);
                        color: #FC8000;
                    }

                }
            }
        }

        img {
            width: 68px;
            height: 68px;
            margin-right: 8px;
        }

        .placemark-description {
            padding: 2.5px 0 7.5px;

            p {
                font-family: 'Days One', sans-serif;
                @include font-params (400, 20px, 25px, 0.016em);
                color: $link_color;
                margin-bottom: 7px;
                text-align: start;

                &:last-child {
                    margin-bottom: 0;
                    font-family: 'Nunito Sans', sans-serif;
                    @include font-params (900, 16px, 22px, 0.05em);

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../../../public/images/main/icon-likes.svg') no-repeat center;
                        vertical-align: middle;
                    }

                    span {
                        @include font-params (900, 24px, 33px, 0.05em);
                        margin-left: 17px;
                        color: #FC8000;
                    }
                }
            }
        }
    }
}


.main__map {
    text-align: center;
    width: 100%;

    h2 {
        @include font-params (400, 40px, 51px, 0.016em);
        color: $link_color;
        margin: 43px 0;
    }

    .map__wrapper {
        width: 100%;
        height: 700px;

    }
}

.ymaps-2-1-79-map {
    width: 100% !important;
    height: 100% !important;
}

.ymaps-2-1-79-balloon {
    border-radius: 10px;
    width: 311px;
    left: -127px !important;
    box-shadow: none !important;

    .ymaps-2-1-79-balloon__layout {
        border-radius: 10px;
        position: relative;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

        .ymaps-2-1-79-balloon__close {
            display: none;
        }

        .ymaps-2-1-79-balloon__content {
            padding: 0;
            margin: 0;

            .balloon__container {
                width: 311px;
                height: 188px;
                border: 1px solid #CBD2E0;
                box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                padding: 12.5px 15px 22.5px;

                h2 {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 20px, 25px, 0.016em);
                    text-align: center;
                    margin: 0;
                    margin-bottom: 5px;
                }

                >div {
                    @include flex-params (row, space-between, baseline);
                    margin-bottom: 10px;

                    p {
                        @include font-params (900, 16px, 22px, 0.05em);
                        color: #221F63;

                        &:first-child {
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../public/images/main/icon-likes.svg') no-repeat center;
                                vertical-align: middle;
                            }
                        }

                        &:last-child {
                            @include font-params (900, 36px, 49px, 0.05em);
                            color: #FC8000;
                        }

                    }
                }

                a {
                    display: block;
                    text-align: center;
                    width: 281px;
                    height: 63px;
                    padding-top: 19px;
                    background: #FC8000;
                    border-radius: 10px;
                    @include font-params (700, 18px, 25px, 0.05em);
                    color: #fff;
                }
            }
        }
    }

    .ymaps-2-1-79-balloon__tail {
        z-index: 0 !important;
        width: 67px;
        height: 67px;
        display: block;
        position: absolute;
        left: 125px;
        bottom: -18px;
        box-shadow: none;
        background: url('../../../public/images/main/balloon-arrow-icon.svg') no-repeat center;
        transform: none !important;

        &::after {
            content: none;
        }
    }
}

.ymaps-2-1-79-svg-icon {
    width: 123px !important;
    height: 123px !important;
    background: url('../../../public/images/main/clusterer-bg.png') no-repeat center !important;
    background-size: cover;

    .ymaps-2-1-79-svg-icon-content {
        display: block;
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        position: relative !important;

        ymaps {
            position: absolute;
            top: 50% !important;
            left: 50% !important;
            color: #FC8000;
            transform: translate(-50%, -50%);
            @include font-params (400 !important, 48px !important, 62px !important, 0.05em !important);
            font-family: 'Days One', sans-serif !important;
        }
    }
}