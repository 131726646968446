@media only screen and (max-width: 1023px) and (min-width: 311px) {
    header {
        position: fixed;
        width: 100%;
        background-color: #fff;
        z-index: 100;

        .header__mail-row {
            a {
                @include block-pos-mobile;
                justify-content: center;
            }
        }

        .header__nav {
            @include block-pos-mobile;
            padding: 13.5px 0;

            .logo {
                img {
                    width: 57px;
                    margin-right: 5px;
                }

                h2 {
                    @include font-params (400, 27px, 120%, 0.01em);
                }
            }

            nav {
                display: none;
            }

            .private-area__button {
                display: none;
            }

            .header_buttons {
                display: none;
            }
        }

        .hamburger-button {
            display: block;
            outline: none;

            span {
                display: block;
                width: 44px;
                height: 4px;
                margin-bottom: 13px;
                background-color: #204BDB;
                border-radius: 10px;
                transition: transform .4s;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.open {
                span:nth-of-type(1) {
                    transform: rotate(45deg) translate(13px, 11px);
                }

                span:nth-of-type(2) {
                    opacity: 0;
                }

                span:nth-of-type(3) {
                    transform: rotate(-45deg) translate(12px, -12px);
                }
            }
        }

        .hamburger-menu {
            z-index: 10;
            position: absolute;
            @include flex-params (column, flex-start, center);
            top: 93px;
            right: 0;
            width: 100%;
            max-height: 0;
            background-color: #fff;
            text-align: center;
            border-radius: 0 0 10px 10px;
            transition: max-height 0.25s ease-out;

            &.open {
                max-height: 409px;
                padding-bottom: 20px;

                >div {
                    max-height: 409px;
                }
            }

            >div {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.25s ease-out;
            }

            .private-area__button {
                display: block;
            }

            .header_buttons {
                display: block;
                padding: 0;

                a {
                    width: 148px;
                    margin: auto;

                    &.authorization {
                        padding: 19px 0;
                        margin: auto;
                        margin-bottom: 30px;
                    }

                    &.registration {
                        width: 184px;
                        padding: 19px 0;
                    }
                }
            }

            ul {
                display: block;

                li {
                    margin-bottom: 30px;

                    a {
                        @include font-params (700, 24px, 36px, 0.05em);

                        &:hover {
                            color: #DB0BC0;
                            border-bottom: #DB0BC0 2px solid;
                        }
                    }

                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}