@media only screen and (max-width: 1023px) and (min-width: 311px) {
    .personal-area__wrapper {
        header {
            margin-bottom: 0;

            .header__container {
                @include block-pos-mobile;
                padding: 14.4px 0;

                .logo {
                    img {
                        width: 57px;
                        margin-right: 5px;
                    }

                    h2 {
                        @include font-params (400, 27px, 120%, 0.01em);
                    }
                }

                .hamburger-menu {
                    &.open {
                        max-height: 261px;

                        nav {
                            max-height: max-content;
                        }
                    }

                    nav {
                        max-height: 0;
                        overflow: hidden;
                        max-width: 311px;

                        a {
                            display: block;
                            height: 22px;
                            margin-bottom: 10px;

                            li {
                                height: 100%;
                                @include font-params (400, 14px, 22px, 0.016em);
                                font-family: 'Days One', sans-serif;
                            }
                        }
                    }
                }
            }
        }

        .personal-area__container {
            @include block-pos-mobile;
            padding-top: 110px;

            .personal-area__title {
                margin-bottom: 10px;
                flex-direction: column;

                .personal-area__user {
                    @include flex-params (row, flex-start, flex-start);
                    width: 100%;
                    margin-bottom: 10px;

                    .title_info {
                        align-self: center;

                        h1 {
                            @include font-params (400, 28px, 36px, 0.016em);
                        }
                    }
                }

                .favorite__title {
                    width: 100%;

                    h2 {
                        @include font-params (400, 28px, 36px, 0.016em);
                        color: $link_color;
                    }

                    p {
                        @include font-params (400, 16px, 25px, 0.016em);
                        color: #1A202C;

                        a {
                            @include font-params (800, 16px, 25px, 0.016em);
                            color: #1A202C;
                        }
                    }
                }
            }

            .personal-area__main {
                @include block-pos-mobile;
                padding-bottom: 20px;

                nav {
                    display: none;
                }

                section {
                    width: 100%;
                }

                .main__content {
                    padding: 10px;
                    width: 311px;

                    h2,
                    h3 {
                        @include font-params (400, 20px, 48px, 0.016em);
                    }

                    p {}

                    .account-details {
                        display: flex;
                        flex-direction: column;

                        >div {
                            border: 1px solid #CBD2E0;
                            border-radius: 5px;
                            padding: 25px 10px;
                            width: 291px;
                            margin-right: 0;
                            margin-bottom: 10px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.subscription_links {
                                border: none;
                                text-align: center;

                                a {
                                    @include font-params (700, 18px, 25px, 0.016em);
                                    display: block;
                                    padding-top: 19px;
                                    margin: auto;
                                    height: 63px;
                                    width: 225px;
                                    background-color: #FC8000;
                                    border-radius: 10px;
                                    color: #fff;
                                    margin-bottom: 5px;

                                    &:last-child {
                                        font-weight: 400;
                                        display: inline;
                                        background: none;
                                        color: #1A202C;
                                    }
                                }
                            }
                        }
                    }

                    .user-photo__caption {
                        align-self: flex-start;
                        @include font-params (400, 16px, 20px, 0.01em);
                        color: #717D96;
                        margin-bottom: 10px;
                        text-align: left;

                        &.upload {
                            font-weight: 700;
                        }
                    }

                    .user-photo__container {
                        align-self: flex-start;
                        @include flex-params (row, flex-start, center);
                        margin-bottom: 30px;

                        .user-photo {
                            width: 112px;
                            height: 112px;
                            background-color: #F9F9FB;
                            border: 1px solid #EFEFF5;
                            border-radius: 4px;
                            margin-right: 20px;

                            label {
                                display: block;
                                width: 112px;
                                height: 112px;
                                cursor: pointer;

                            }

                            input {
                                display: none;
                                position: relative;
                            }

                            img {
                                object-fit: cover;
                                max-width: 112px;
                                max-height: 112px;
                            }
                        }

                        .delete-photo {
                            width: 36px;
                            height: 36px;
                            background-size: cover;
                        }
                    }

                    .edit-user__form {
                        label {
                            text-align: left;
                            @include font-params (400, 16px, 20px, 0.01em);
                            color: #717D96;
                            margin-bottom: 30px;
                            display: block;
                            position: relative;

                            &.password {

                                input[type='password'] {
                                    color: #FC8000;
                                }

                                p {
                                    position: absolute;
                                    top: 50px;
                                    right: 20px;
                                    width: 30px;
                                    height: 20px;
                                    background-size: cover;
                                    cursor: pointer;

                                    &.active {
                                        background-size: cover;
                                    }
                                }
                            }


                            input {
                                @include font-params (400, 18px, 25px, 0.01em);
                                color: #717D96;
                                width: 100%;
                                padding: 20px;
                                display: block;
                                border: none;
                                border-bottom: 2px solid #A0ABC0;
                                background: none;

                                &::placeholder {
                                    color: #717D96;
                                }

                                &.valid {
                                    background-position-x: 30px;
                                    padding-left: 70px;
                                }

                                &:invalid {
                                    background: none;
                                }
                            }
                        }

                        .error-message {
                            position: absolute;
                            transform: translateY(-115%);

                            p {
                                color: #DB0BC0;
                                @include font-params (700, 18px, 22px, 0.01em);
                            }
                        }

                        input[type='file'] {
                            width: 168px;
                            height: 168px;
                        }

                        input[type='submit'] {
                            display: block;
                            background-color: #FC8000;
                            width: 225px;
                            height: 63px;
                            margin: auto;
                            color: #fff;
                            border: none;
                            border-radius: 10px;
                            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.04);
                            @include font-params (700, 20px, 25px, 0.01em);
                            margin-bottom: 20px;
                            cursor: pointer;
                        }
                    }

                    .places-table__wrapper {
                        position: relative;

                        * {
                            &::-webkit-scrollbar {
                                width: 10px;
                                border-left: 1px solid #CBD2E0;
                            }

                            &::-webkit-scrollbar-track {
                                background: #fff;
                                border-left: 1px solid #CBD2E0;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #CBD2E0;
                            }

                            &::-webkit-scrollbar-button:single-button {
                                background-color: #fff;
                                display: block;
                                height: 10px;
                                width: 10px;
                            }

                            &::-webkit-scrollbar-button:single-button:vertical:decrement {
                                background-position: 0 2px;
                                background-size: cover;
                            }

                            &::-webkit-scrollbar-button:single-button:vertical:increment {
                                background-position: 0 -2px;
                                background-size: cover;
                            }
                        }

                        .places-table {
                            overflow-y: scroll;
                            height: 122px;
                            border: 1px solid #CBD2E0;
                            width: 291px;
                            z-index: 0;
                            margin-top: 40px;
                            scrollbar-color: #fff;

                            table {
                                width: 100%;
                                border-collapse: collapse;

                                tr {
                                    height: 40px;

                                    &:last-child {
                                        td {
                                            border: none;
                                        }
                                    }
                                }

                                td {
                                    text-align: center;
                                    @include font-params (400, 14px, 19px, 0.016em);
                                    color: #1A202C;
                                    border-bottom: 1px solid #CBD2E0;

                                    &:first-child {
                                        text-align: left;
                                        padding-left: 15px;
                                    }

                                    &:last-child {
                                        padding-right: 15px;
                                    }
                                }

                                thead {
                                    position: absolute;
                                    left: 0;
                                    height: 40px;
                                    width: 291px;
                                    top: -40px;
                                    border: 1px solid #CBD2E0;
                                    border-radius: 5px 5px 0px 0px;

                                    tr {
                                        display: flex;
                                        width: 100%;

                                        th {
                                            border-radius: 5px 5px 0px 0px;
                                            display: block;
                                            height: 100%;
                                            flex-grow: 1;
                                            background-color: #fff;
                                            @include font-params (400, 14px, 19px, 0.016em);
                                            border-bottom: 1px solid #CBD2E0;
                                            color: #1A202C;
                                            background-color: #fff;

                                            &:first-child {
                                                flex-grow: 1;

                                                p {
                                                    padding-left: 15px;
                                                }
                                            }

                                            &:last-child {
                                                p {
                                                    padding-left: 20px;
                                                }
                                            }

                                            p {
                                                padding-left: 28px;
                                                padding-top: 18px;
                                                position: absolute;
                                                @include font-params (400, 14px, 19px, 0.016em);
                                                color: #1A202C;

                                            }
                                        }
                                    }
                                }

                                tbody {
                                    overflow-y: scroll;
                                    height: 120px;

                                    tr {
                                        td {
                                            &:first-child {
                                                width: 100px;
                                            }
                                        }
                                    }

                                    button {
                                        background-color: #00D2D0;
                                        width: 90px;
                                        height: 27px;
                                        @include font-params (700, 11px, 19px, 0.016em);
                                        color: #fff;

                                        &:disabled {
                                            background-color: #fff;
                                            border: 1px solid #CBD2E0;
                                            color: #A0ABC0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .personal-area__favorite {
                    .fishing-place__wrapper {
                        position: relative;
                        z-index: 1;

                        &.open-delete-btn {
                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                mix-blend-mode: multiply;
                                background-color: #A0ABC0;
                                box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, .5);
                                border-radius: 10px;
                            }
                        }

                        .delete-button {
                            position: absolute;
                            width: 155px;
                            height: 40px;
                            background-color: #00A5FF;
                            border-radius: 10px;
                            @include font-params (700, 16px, 22px, 0.016em);
                            color: #fff;
                            z-index: 10;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            box-shadow: 0 0 5px 3px rgba(0, 0, 0, .5);
                        }
                    }
                }
            }
        }
    }
}