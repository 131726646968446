@media only screen and (max-width: 1023px) and (min-width: 311px) {
    footer {
        .footer__wrapper {
            @include block-pos-mobile;
            flex-direction: column;
            padding: 23.5px 0;

            nav {
                ul {
                    flex-direction: column;
                    align-items: center;

                    li {
                        margin: 0;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}