@media only screen and (max-width: 767px) and (min-width: 311px) {
    .main__faq {
        @include block-pos-mobile;
        padding: 21px 0 20px;
        color: $link_color;
        @include flex-params (column, flex-start, center);

        h2 {
            @include font-params (400, 28px, 32px, 0.016em);
            margin-bottom: 15px;
        }

        ul {
            display: flex;
            margin-bottom: 15px;

            li {
                padding: 0 10px;
                border-right: 1px solid #717D96;

                &:last-child {
                    border: none;
                }

                button {
                    @include font-params (400, 10px, 10px, 0.05em);
                    color: #717D96;

                    &.active {
                        color: $link_color;
                    }
                }
            }
        }

        .faq__wrapper {
            display: flex;
            grid-gap: 10px;
            margin-bottom: 10px;
            flex-wrap: wrap;

            >div {
                margin: auto;
                width: 311px;
            }

            .faq__container {
                padding: 10px;
                border: 1px solid #CBD2E0;
                border-radius: 5px;
                height: 76px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.active {
                    height: max-content;
                }

                >div {

                    h3 {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 12px, 18px, 0.016em);
                        max-width: 250px;
                    }
                }

                p {
                    @include font-params (400, 12px, 18px, 0.05em);
                    color: #717D96;

                    a {
                        text-decoration: underline;
                        color: #221F63;
                    }

                    span {
                        color: #00A5FF;
                    }
                }
            }
        }

        .main__faq_prompt {
            @include font-params (400, 10px, 18px, 0.05em);
            color: #666666;
            text-align: center;

            a {
                text-decoration: underline;
                color: #666666;
            }
        }
    }
}