@media only screen and (max-width: 1023px) and (min-width: 311px) {

    .registration-page {
        @include block-pos-mobile;
        width: max-content;
        padding-top: 0;
        @include flex-params (column, flex-start, center);

        .registration-page__steps {
            @include flex-params (row, space-between, center);
            position: fixed;
            width: 100%;
            padding: 15px 20px;
            background-color: #F7F9FC;
            border-radius: 0 0 4px 4px;
            margin-bottom: 34px;
            z-index: 30;
            box-shadow: 0px 2px 4px 0px rgb(0 0 0 / .2);

            li {
                width: 15%;
                @include flex-params (column, flex-start, center);
                text-align: center;
                color: #fff;
                position: relative;

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: -80px;
                    top: 14px;
                    height: 4px;
                    width: 60px;
                    background-color: #A0ABC0;
                    border-radius: 4px;
                }

                .step_icon {
                    background-color: #A0ABC0;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    padding-top: 5px;
                    @include font-params (700, 16px, 22px, 0.05em);
                    font-family: 'Roboto', sans-serif;
                    margin-bottom: 0;
                }

                .step_caption {
                    @include font-params (600, 11px, 15px, 0.01em);
                    color: #9595B1;
                    display: none;
                }
            }
        }

        >div {
            padding-top: 77px;
            width: 311px;
        }

        .user-photo__caption {
            @include font-params (400, 11px, 16px, 0.01em);
            margin-bottom: 4px;
        }

        .user-photo__container {
            margin-bottom: 16px;

            .user-photo {
                width: 112px;
                height: 112px;
                margin-right: 12px;

                label {
                    width: 112px;
                    height: 112px;

                }

                img {
                    max-width: 112px;
                    max-height: 112px;
                }
            }

            .delete-photo {
                width: 24px;
                height: 24px;
            }
        }

        .registration-page__form {
            width: 311px;

            label {
                @include font-params (400, 11px, 16px, 0.01em);
                margin-bottom: 16px;

                &.password {
                    p {
                        position: absolute;
                        top: 36px;
                        right: 16px;
                        width: 24px;
                        height: 16px;
                        background-size: cover;
                        cursor: pointer;

                        &.active {
                            background-size: cover;
                        }
                    }
                }


                input {
                    @include font-params (400, 16px, 24px, 0.005em);
                    color: #717D96;
                    width: 100%;
                    padding: 16px;
                    display: block;
                    border: none;
                    border-bottom: 1px solid #A0ABC0;
                    background: none;

                    &::placeholder {
                        color: #717D96;
                    }

                    &.valid {
                        background-position-x: 30px;
                        padding-left: 70px;
                    }

                    &:invalid {
                        background: none;
                    }
                }
            }

            .error-message {
                position: absolute;
                transform: translateY(-100%);

                &:last-child {
                    transform: translateY(-20px);
                    position: relative;
                }

                p {
                    color: #DB0BC0;
                    @include font-params (700, 11px, 16px, 0.01em);
                }
            }

            input[type='file'] {
                width: 112px;
                height: 112px;
            }

            input[type='submit'] {
                background-color: #FC8000;
                width: 200px;
                height: 48px;
                @include font-params (700, 15px, 20px, 0.01em);
                margin-bottom: 10px;
                cursor: pointer;
            }

            .apply-agreement {
                text-align: left;

                label {
                    @include font-params (400, 12px, 16px, 1);
                    width: 259px;
                    padding-left: 29px;
                    position: relative;
                }

                input[type='checkbox'] {
                    width: 24px;
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
                }

                input[type='checkbox']+label {
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;

                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        width: 24px;
                        height: 24px;
                        background-size: cover;
                        margin-right: 5px;
                    }
                }

                input[type='checkbox']:checked+label::before {
                    background-size: cover;
                }
            }
        }

        .registration-page__subscription-payment {
            padding: 96px 0 20px;

            >div {
                width: 311px;
                display: grid;
                align-items: center;
                grid-template-columns: 279px;
                border: 1px solid #CBD2E0;
                padding: 34px 16px;
                margin-bottom: 20px;

                img {
                    width: 196px;
                    height: max-content;
                    grid-row: 1;
                    margin: auto;
                    margin-bottom: 10px;
                }

                ul {
                    color: #221F63;

                    &:nth-child(2) {
                        grid-row: 3;
                    }

                    &:nth-child(3) {
                        grid-row: 2;

                        p {
                            display: none;
                        }
                    }


                    p,
                    span {
                        @include font-params (800, 12px, 16px, 0.05em);
                        margin-bottom: 4px;
                    }

                    p {
                        padding-left: 0;
                    }

                    li {
                        @include font-params (400, 12px, 16px, 0.01em);
                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                    }
                }

                div {
                    text-align: center;
                    width: 279px;

                    p {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 40px, 51px, 0.016em);
                        color: #FC8000;
                        margin-bottom: 15px;

                        span {
                            display: block;
                            @include font-params (400, 16px, 170%, 0.05em);
                            color: #221F63;
                        }
                    }

                    button {
                        width: 225px;
                        height: 63px;
                        background: #FC8000;
                        border-radius: 10px;
                        color: #fff;
                        @include font-params (400, 18px, 25px, 0.05em);
                    }
                }
            }
        }

        .registration-page__complete {
            text-align: center;
            padding-top: 96px;

            img {
                width: 24px;
                object-fit: cover;
                margin-bottom: 10px;
            }

            p {
                @include font-params (600, 11px, 16px, 0.01em);
                color: #9595B1;
                margin-bottom: 10px;
            }

            a {
                padding: 19px 23.5px;
                @include font-params (700, 18px, 16px, 0.01em);
            }
        }
    }
}