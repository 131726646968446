.container {
    position: relative;
}

.place-cart__wrapper {
    padding-top: 39px;
    border-top: 1px solid #221F63;

    &.open-comments {
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            mix-blend-mode: multiply;
            background-color: #A0ABC0;
            backdrop-filter: blur(2px);
        }
    }

    section {
        @include block-pos;
        display: flex;

        &.place-cart {
            margin-bottom: 43px;
        }

        .place-cart__photo {
            @include flex-params (column, flex-start, center);
            margin-right: 38px;

            img {
                width: 594px;
                height: 378px;
                margin-bottom: 16px;
                object-fit: cover;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .place-cart__description {
            position: relative;
            @include flex-params (column, flex-start, left);
            flex-grow: 1;
            
            >p {
                margin-bottom: 8px;
                @include font-params (400, 18px, 24px, 0.016em);
                font-family: 'Days One', sans-serif;
                color: #717D96;

                &.path {
                    font-family: 'Nunito Sans', sans-serif;
                }

                &.place-area {
                    color: #00A5FF;

                    span {
                        font-family: 'Days One', sans-serif;
                        margin-left: 38px;
                    }
                }

                &.organization-type {
                    margin-bottom: 18px;
                }
            }

            h2 {
                color: $link_color;
                @include font-params (400, 48px, 54px, 0.016em);
                margin-bottom: 8px;
            }

            .statistic {
                @include flex-params (row, space-between, center);
                margin-bottom: 35px;
                padding-right: 20px;

                >div {
                    @include flex-params (row, space-between, center);
                    color: $link_color;

                    &:last-child {
                        margin-right: 0;
                    }

                    p {
                        @include font-params (400, 16px, 24px, 0.05em);

                        &.lake-type {
                            color: #fff;
                            background-color: #DB0BC0;
                            padding: 4px 15px;
                            margin-right: 9px;
                        }

                        &.likes-count {
                            font-weight: 900;
                            margin-right: 12px;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../../public/images/main/icon-likes.svg') no-repeat center;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                        }

                        &.comments {
                            font-weight: 900;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../../public/images/main/icon-comments.svg') no-repeat center;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                        }

                        &.view-count {
                            margin-right: 9px;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../../public/images/main/icon-viewed.svg') no-repeat center;
                                vertical-align: middle;
                            }
                        }
                    }

                    .rating-wrapper {
                        display: flex;
                        align-items: center;

                        .rating {
                            @include font-params (900, 16px, 22px, 0.05em);
                            margin-right: 10px;
                            width: 75px;
                            text-align: end;
                        }

                        .rating-count {
                            font-family: 'Days One', sans-serif;
                            @include font-params (900, 48px, 61px, 0.016em);
                            color: #FC8000;
                            margin-right: 28px;
                        }
                    }

                    .show-on-map {
                        @include font-params (900, 16px, 20px, 0.05em);
                        color: $link_color;
                        text-decoration: underline;
                        text-align: end;
                        display: flex;
                        width: 141px;

                        &::after {
                            content: '';
                            display: block;
                            width: 115px;
                            height: 44px;
                            background: url('../../../../public/images/main/icon-map.svg') no-repeat center;
                        }
                    }

                    .share {
                        background: url('../../../../public/images/main/icon-share.svg') no-repeat center;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            .place-cart_text {
                font-family: 'Nunito Sans', sans-serif;
                color: #2D3648;
                margin-bottom: 35px;
                @include font-params (400, 16px, 22px, 0.05em);
            }

            .additional-info {
                padding: 21px 11px;
                background: #EDF0F7;
                border-radius: 10px;
                margin-bottom: 19px;

                >div {
                    @include flex-params (row, space-between, center);
                    color: #221F63;

                    &.info {
                        margin-bottom: 10px;

                        .avg-check {
                            font-family: 'Days One', sans-serif;
                            @include font-params (400, 20px, 25px, 0.016em);
                            color: #FC8000;

                            span {
                                @include font-params (400, 12px, 16px, 0.05em);
                                display: block;
                                color: $link_color;
                            }
                        }

                        .to-favorite {
                            @include font-params (400, 16px, 22px, 0.05em);
                            text-decoration: underline;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 46px;
                                height: 47px;
                                background: url('../../../../public/images/main/icon-favorite.png') no-repeat center;
                                vertical-align: middle;
                                margin-right: 10px;
                            }
                        }

                        .numbers {
                            display: flex;

                            p {
                                @include font-params (400, 20px, 26px, 0.016em);
                                font-family: 'Days One', sans-serif;
                            }

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 47px;
                                height: 47px;
                                background: url('../../../../public/images/main/icon-call.png') no-repeat center;
                                vertical-align: middle;
                                margin-right: 10px;
                            }
                        }
                    }

                    &.messanger {
                        padding-right: 10px;

                        p {
                            @include font-params (400, 16px, 22px, 0.05em);
                        }

                        ul {
                            display: flex;

                            li {
                                margin-right: 10px;
                                height: 47px;

                                a {
                                    height: 100%;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .place-cart__footer {
                @include flex-params (row, space-between, center);

                >button {
                    @include font-params (700, 18px, 25px, 0.05em);
                    height: 63px;
                    width: 225px;
                    background: #FC8000;
                    border-radius: 10px;
                    color: #fff;
                }

                div {
                    button {
                        @include font-params (400, 16px, 22px, 0.05em);
                        color: #DB0BC0;
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    .place-cart__terms {
        flex-direction: column;
        align-items: center;
        padding: 43px 0 37px;
        color: #221F63;

        h2 {
            @include font-params (400, 40px, 51px, 0.016em);
            margin-bottom: 44px;
        }

        >div {
            width: 100%;
            @include flex-params (row, space-between, flex-start);

            h3 {
                font-family: 'Days One', sans-serif;
                @include font-params (400, 20px, 25px, 0.016em);
                margin-bottom: 5px;
            }

            p {
                @include font-params (400, 16px, 22px, 0.05em);
                width: 404px;
            }

            ul {
                li {
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background-color: #FC8000;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .place-cart__rates {
        flex-direction: column;
        align-items: center;
        padding: 43px 0 37px;
        color: #221F63;

        h2 {
            @include font-params (400, 40px, 51px, 0.016em);
            margin-bottom: 44px;
        }

        >div {
            @include flex-params (row, space-between, flex-start);

            div {
                padding: 24.5px 20px;
                font-family: 'Days One', sans-serif;
                background: #EDF0F7;
                border-radius: 10px;
                margin-right: 17px;

                &:last-child {
                    margin-right: 0;
                }

                h3 {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 20px, 25px, 0.016em);
                    width: 384px;
                    color: #00A5FF;
                    margin-bottom: 10px;
                }

                button {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 16px, 21px, 0.05em);
                    color: $link_color;
                    text-decoration: underline;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../../../../public/images/main/filter-arrow.svg') no-repeat center;
                        vertical-align: middle;
                        transform: rotate(180deg);
                        margin-left: 5px;
                    }
                }

                ul {

                    li {
                        color: $link_color;
                        @include font-params (400, 16px, 22px, 0.05em);

                        &:last-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .place-cart__stocking {
        @include flex-params (column, flex-start, center);
        color: $link_color;
        margin-bottom: 43px;

        h2 {
            margin-bottom: 43px;
            @include font-params (400, 40px, 51px, 0.016em);
        }

        >div {
            display: flex;
            flex-direction: column;
            width: 100%;

            .stocking_table {
                @include flex-params (column, flex-start, center);

                table {
                    width: 100%;
                    height: 226px;
                    border-collapse: collapse;
                    margin-bottom: 20px;

                    tr:nth-child(even) {
                        background-color: #F7F9FC;
                    }

                    thead {
                        tr {
                            background-color: #E2E7F0;

                            th {
                                height: 34px;
                                text-align: left;
                                padding-left: 10px;

                                &:nth-child(3) {
                                    text-align: right;
                                    padding-right: 95px;
                                }

                                &:nth-child(4) {
                                    padding-left: 17px;
                                }

                                &:nth-child(5) {
                                    text-align: right;
                                }

                                &:nth-child(6) {
                                    text-align: right;
                                    padding-right: 10px;
                                }

                                &::after {
                                    content: '';
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    background: url('../../../../public/images/main/filter-arrow.svg') no-repeat center;
                                    transform: rotate(180deg);
                                    vertical-align: middle;
                                }

                                button {
                                    color: $link_color;
                                    font-family: 'Days One', sans-serif;
                                    @include font-params (400, 16px, 22px, 0.016em);
                                }
                            }
                        }
                    }

                    tbody {

                        td {
                            padding-left: 10px;
                            height: 32px;
                            @include font-params (400, 16px, 22px, 0.05em);

                            &:nth-child(1) {
                                text-align: left;
                                width: 130px;
                            }

                            &:nth-child(2) {
                                width: 65px;
                            }

                            &:nth-child(3) {
                                text-align: right;
                                padding-right: 100px;
                                width: 400px;
                            }

                            &:nth-child(4) {
                                width: 230px;
                            }

                            &:nth-child(5) {
                                text-align: right;
                                width: 120px;
                            }

                            &:nth-child(6) {
                                text-align: right;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                >button {
                    height: 63px;
                    width: 225px;
                    background: #FC8000;
                    border-radius: 10px;
                    @include font-params (700, 18px, 25px, 0.05em);
                    color: #fff;
                    margin-bottom: 36px;
                }
            }

            .add-terms {
                background: #EDF0F7;
                border-radius: 10px;
                padding: 62.5px 20px;

                h3 {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 20px, 25px, 0.016em);
                    color: #FC8000;
                    margin-bottom: 10px;
                }

                p {
                    @include font-params (400, 16px, 22px, 0.05em);
                }
            }
        }
    }

    .place-cart__reviews {
        flex-direction: column;
        padding: 45px 0 73px;

        .reviews_title {
            @include flex-params (row, space-between, center);
            margin-bottom: 34px;

            div {
                @include flex-params (row, flex-start, center);

                .filter-arrow {
                    width: 24px;
                    height: 24px;
                    background: url('../../../../public/images/main/icon-filter-arrow-cart.svg') no-repeat center;
                    margin-right: 10px;

                    &.down {
                        transform: rotate(180deg);
                    }
                }

                p {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 18px, 23px, 0.016em);
                    color: #1A1A1A;
                    text-decoration: underline;
                }
            }

            h2 {
                @include font-params (400, 40px, 51px, 0.016em);
                color: $link_color;
                margin-left: 84px;
            }

            >button {
                width: 225px;
                height: 63px;
                background-color: #00A5FF;
                border-radius: 10px;
                color: #fff;
                @include font-params (700, 18px, 25px, 0.05em);
            }
        }

        .reviews__wrapper {
            flex-wrap: wrap;
            @include flex-params (row, space-between, center);
            margin-bottom: 10px;

            .review__container {
                display: flex;
                max-width: 634px;
                max-height: 182px;
                margin-bottom: 48px;

                .user-photo {
                    margin-right: 20px;

                    img {
                        width: 144px;
                        height: 182px;
                        object-fit: cover;
                    }
                }

                .review-text {
                    margin: 4px 0 10px;
                }

                p {
                    @include font-params (400, 16px, 22px, 0.05em);
                    color: #1A1A1A;

                    &.user-name {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 20px, 25px, 0.016em);
                        margin-bottom: 12px;

                        span {
                            @include font-params (400, 20px, 27px, 0.016em);
                            margin-left: 10px;
                        }
                    }
                }

                .details__footer {
                    display: flex;
                    justify-content: space-between;

                    >div {
                        display: flex;

                        .likes-count {
                            @include font-params (900, 16px, 22px, 0.05em);
                            margin-right: 10px;
                            color: $link_color;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../../public/images/main/icon-likes.svg') no-repeat center;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                        }

                        .comment-count {
                            @include font-params (900, 16px, 22px, 0.05em);
                            color: $link_color;
                            text-decoration: none;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: url('../../../../public/images/main/icon-comments.svg') no-repeat center;
                                vertical-align: middle;
                                margin-right: 5px;
                            }
                        }
                    }

                    >p {
                        @include font-params (900, 16px, 22px, 0.05em);
                        text-decoration: underline;
                    }
                }
            }
        }

        .show-more {
            height: 63px;
            width: 205px;
            margin: auto;
            background: #FC8000;
            border-radius: 10px;
            color: #fff;
            @include font-params (700, 18px, 25px, 0.05em);
        }
    }

    .comments__wrapper {
        max-width: 100%;
        @include flex-params (column, space-between, center);
        background: #EDF0F7;
        padding: 43px 0 107px;

        .comments_title {
            display: flex;
            position: relative;
            margin-bottom: 43px;

            h2 {
                @include font-params (400, 40px, 51px, 0.016em);
                color: $link_color;
            }

            >div {
                position: absolute;
                display: flex;
                left: 666px;
                top: 12px;
                color: $link_color;

                .filter-arrow {
                    width: 24px;
                    height: 24px;
                    background: url('../../../../public/images/main/icon-filter-arrow-cart.svg') no-repeat center;
                    margin-right: 10px;

                    &.down {
                        transform: rotate(180deg);
                    }
                }

                p {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 18px, 23px, 0.016em);
                    color: $link_color;
                    text-decoration: underline;
                    width: 82px;
                }
            }
        }
    }
}