@media only screen and (max-width: 1023px) and (min-width: 311px) {
    main {
        padding-top: 95px;

        &.disable {
            &::after {
                content: '';
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 0;
                mix-blend-mode: multiply;
                background-color: #A0ABC0;
                z-index: 100;
                box-shadow: inset 0 0 8px 6px rgba(255, 255, 255, 1);
            }
        }

        .main__title-wrapper {
            background: url('../../../../../public/images/main/main-mobile-bg.png') no-repeat center;
            background-size: cover;

            .main__title {
                padding: 192.5px 0 274.5px;
                @include block-pos-mobile;
                color: $link_color;
                text-align: center;

                h1 {
                    @include font-params (400, 28px, 36px, 0.016em);
                    margin-bottom: 5px;
                }

                p {
                    @include font-params (400, 16px, 22px, 0.05em);
                    margin: auto;
                    margin-bottom: 5px;
                    max-width: 235px;
                }

                >div {
                    width: 311px;

                    input {
                        width: 311px;
                        height: 58px;
                        padding-right: 76px;
                        @include font-params (700, 18px, 25px, 0.05em);
                    }

                    button {
                        width: 58px;
                        height: 58px;
                        background: url('../../../../../public/images/main/search-icon.svg') #204BDB no-repeat center;
                    }
                }
            }
        }

        .main__fishing-places {
            padding: 10px 0 23px;
            @include block-pos-mobile;

            .fishing-places__title {
                flex-direction: column;
                margin-bottom: 14px;

                >button {
                    @include font-params (400, 16px, 20px, 0.016em);

                    &.filter-button {
                        display: block;
                        height: 40px;
                        z-index: 15;
                        width: 100%;

                        &.open {

                            &::after {
                                content: none;
                            }
                        }
                    }
                }

                h2 {
                    @include font-params (400, 24px, 31px, 0.016em);
                    margin: 0;
                    max-width: 208px;
                    margin-bottom: 25px;
                }

                >div {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    p {
                        @include font-params (400, 16px, 20px, 0.016em);
                    }
                }
            }

            .fishing-places__list {
                text-align: start;
                @include flex-params (row, space-between, flex-start);
                margin-bottom: 30px;

                .fishing-places__filter {
                    display: block;
                    border: none;
                    border-radius: 0;
                    margin: 0;
                    padding: 0;
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 280px;
                    height: 100%;
                    background-color: #fff;
                    z-index: 150;
                    transform: translateX(-100%);
                    transition: transform 0.25s ease-out;

                    &.show {
                        transform: translateX(0);
                        padding-bottom: 15px;
                        box-shadow: 0 0 8px 6px rgba(0, 0, 0, 0.5);

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }

                    .filter_title {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 18px, 18px, 0.016em);
                        background-color: #DB0BC0;
                        color: #fff;
                        width: 100%;
                        padding: 15px 0;
                        margin-bottom: 15px;

                        &::after {
                            content: '';
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            vertical-align: middle;
                            margin-left: 10px;
                            background: url('../../../../../public/images/main/mobile-filter-cloce-icon.svg') no-repeat center;
                        }
                    }

                    .filter-category {
                        padding: 0 10px;
                        font-family: 'Days One', sans-serif;
                        color: #FC8000;
                        margin-bottom: 10px;
                        @include font-params (400, 14px, 20px, 0.016em);
                        width: 100%;
                        position: relative;
                        cursor: pointer;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 3px;
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            transform: rotate(-90deg);
                        }
                    }

                    >div {
                        >div {
                            position: absolute;
                            background-color: #fff;
                            z-index: 15;

                            .filter-subcategory {
                                display: block;
                                padding-left: 10px;
                                @include font-params (700, 14px, 20px, 0.016em);
                                color: $link_color;
                                margin-bottom: 10px;
                                width: 0;
                                overflow: hidden;
                                transition: width 0.15s ease-out;

                                &.show {
                                    width: 100%;
                                }

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: 2px;
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }
                }
            }

            .show-more {
                width: 225px;
                height: 63px;
                background: #204BDB;
                border-radius: 10px;
                color: #fff;
                @include font-params (700, 18px, 25px, 0.05em);
            }
        }

    }

    .ymaps-2-1-79-image-with-content {
        z-index: 5 !important;
        position: absolute;

        .placemark__container {
            display: flex;
            width: max-content;
            background-color: #fff;
            border-radius: 68px 5px 5px 68px;

            .placemark__content {
                height: 68px;
                min-width: 180px;
                padding-right: 10px;

                h2 {
                    @include font-params (400, 20px, 25px, 0.016em);
                    white-space: nowrap;
                    margin: 0;
                }

                >div {
                    @include flex-params (row, space-between, baseline);
                    margin-bottom: 10px;

                    p {
                        @include font-params (900, 16px, 22px, 0.05em);
                        color: #221F63;

                        &:first-child {
                            &::before {
                                content: '';
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                vertical-align: middle;
                            }
                        }

                        &:last-child {
                            @include font-params (900, 24px, 33px, 0.05em);
                            color: #FC8000;
                        }

                    }
                }
            }

            img {
                width: 68px;
                height: 68px;
                margin-right: 8px;
            }

            .placemark-description {
                padding: 2.5px 0 7.5px;

                p {
                    font-family: 'Days One', sans-serif;
                    @include font-params (400, 20px, 25px, 0.016em);
                    color: $link_color;
                    margin-bottom: 7px;
                    text-align: start;

                    &:last-child {
                        margin-bottom: 0;
                        font-family: 'Nunito Sans', sans-serif;
                        @include font-params (900, 16px, 22px, 0.05em);

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            vertical-align: middle;
                        }

                        span {
                            @include font-params (900, 24px, 33px, 0.05em);
                            margin-left: 17px;
                            color: #FC8000;
                        }
                    }
                }
            }
        }
    }


    .main__map {
        h2 {
            @include font-params (400, 40px, 51px, 0.016em);
            color: $link_color;
            margin: 43px 0;
        }

        .map__wrapper {
            height: 500px;

        }
    }

    .ymaps-2-1-79-balloon {
        border-radius: 10px;
        width: 311px;
        left: -127px !important;
        box-shadow: none !important;

        .ymaps-2-1-79-balloon__layout {
            border-radius: 10px;
            position: relative;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

            .ymaps-2-1-79-balloon__close {
                display: none;
            }

            .ymaps-2-1-79-balloon__content {
                padding: 0;
                margin: 0;

                .balloon__container {
                    width: 311px;
                    height: 188px;
                    border: 1px solid #CBD2E0;
                    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
                    border-radius: 10px;
                    padding: 12.5px 15px 22.5px;

                    h2 {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 20px, 25px, 0.016em);
                        text-align: center;
                        margin: 0;
                        margin-bottom: 5px;
                    }

                    >div {
                        @include flex-params (row, space-between, baseline);
                        margin-bottom: 10px;

                        p {
                            @include font-params (900, 16px, 22px, 0.05em);
                            color: #221F63;

                            &:first-child {
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    vertical-align: middle;
                                }
                            }

                            &:last-child {
                                @include font-params (900, 36px, 49px, 0.05em);
                                color: #FC8000;
                            }

                        }
                    }

                    a {
                        display: block;
                        text-align: center;
                        width: 281px;
                        height: 63px;
                        padding-top: 19px;
                        background: #FC8000;
                        border-radius: 10px;
                        @include font-params (700, 18px, 25px, 0.05em);
                        color: #fff;
                    }
                }
            }
        }

        .ymaps-2-1-79-balloon__tail {
            z-index: 0 !important;
            width: 67px;
            height: 67px;
            display: block;
            position: absolute;
            left: 125px;
            bottom: -18px;
            box-shadow: none;
            transform: none !important;

            &::after {
                content: none;
            }
        }
    }

    .ymaps-2-1-79-svg-icon {
        width: 123px !important;
        height: 123px !important;
        background-size: cover;

        .ymaps-2-1-79-svg-icon-content {
            display: block;
            width: 100% !important;
            height: 100% !important;
            top: 0 !important;
            position: relative !important;

            .ymaps {
                position: absolute;
                top: 50% !important;
                left: 50% !important;
                color: #FC8000;
                transform: translate(-50%, -50%);
                @include font-params (400 !important, 48px !important, 62px !important, 0.05em !important);
                font-family: 'Days One', sans-serif !important;
            }
        }
    }
}