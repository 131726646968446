.fishing-place__cart {
    @include flex-params (row, space-between, top);
    padding: 15px;
    border: 1px solid #CBD2E0;
    border-radius: 10px;
    margin-bottom: 10px;
    color: $link_color;
    width: 100%;
    height: 258px;

    &.active {
        .fishing-place__desc {
            max-width: 252px;

            h3 {
                text-align: left;
            }

            p {
                line-clamp: 5;
                -webkit-line-clamp: 5;
            }
        }

        .fishing-place__types {
            width: 250px;
        }

        .fishing-place__details {
            max-width: 160px;

            a {
                width: 160px;
            }
        }
    }

    >div {
        @include flex-params (column, space-between, left);

        &.fishing-place__pic {
            height: 228px;

            img {
                height: 100%;
            }
        }

        &.fishing-place__desc {
            width: 500px;

            h3 {
                @include font-params (400, 20px, 25px, 0.016em);
                font-family: 'Days One', sans-serif;
                margin-bottom: 13.5px;
            }

            h4 {
                @include font-params (900, 16px, 18px, 0.05em);
                text-decoration: underline;
            }

            >p {
                @include font-params (400, 16px, 22px, 0.05em);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -moz-box;
                display: -webkit-box;
                line-clamp: 3;
                box-orient: vertical;
                -moz-box-orient: vertical;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .desc_footer {
                @include flex-params (row, space-between, center);

                .likes-count {
                    @include font-params (900, 16px, 22px, 0.05em);

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background: url('../../../../public/images/main/icon-likes.svg') no-repeat center;
                        vertical-align: middle;
                        margin-right: 5px;
                    }
                }

                .rating-wrapper {
                    display: flex;
                    align-items: center;

                    .rating {
                        @include font-params (900, 16px, 22px, 0.05em);
                        margin-right: 10px;
                        width: 75px;
                        text-align: end;
                    }

                    .rating-count {
                        font-family: 'Days One', sans-serif;
                        @include font-params (900, 48px, 61px, 0.016em);
                        color: #FC8000;
                    }
                }
            }
        }

        &.fishing-place__types {
            justify-content: flex-start;
            background: #EDF0F7;
            border-radius: 10px;
            padding: 22px 11px;
            width: 291px;

            h3 {
                @include font-params (400, 20px, 25px, 0.016em);
                font-family: 'Days One', sans-serif;
                margin-bottom: 5px;
            }

            p {
                @include font-params (400, 16px, 22px, 0.05em);
            }
        }

        &.fishing-place__details {
            margin-right: 0;
            align-items: center;
            max-width: 178px;

            a {
                display: block;
                width: 178px;
                height: 63px;
                padding-top: 19px;
                text-align: center;
                background-color: #FC8000;
                border-radius: 10px;
                color: #fff;
                @include font-params (700, 18px, 25px, 0.05em);
            }

            .work-time {
                text-align: center;
                @include font-params (400, 16px, 22px, 0.05em);

                span {
                    display: block;
                    @include font-params (900, 16px, 22px, 0.05em);

                }
            }

            .price {
                @include font-params (900, 24px, 33px, 0.05em);
                color: #FC8000;
            }
        }

        .show-on-map {
            @include font-params (900, 16px, 20px, 0.05em);
            color: $link_color;
            text-decoration: underline;
            text-align: end;
            display: flex;
            width: 141px;
            display: none;

            &.show {
                display: flex;

                &::after {
                    content: '';
                    display: block;
                    width: 115px;
                    height: 44px;
                    background: url('../../../../public/images/main/icon-map.svg') no-repeat center;
                }
            }
        }
    }
}

.fishing-place__wrapper {
    @include flex-params (row, flex-start, center);
    margin-bottom: 20px;
    position: relative;

    &:last-child {
        margin-bottom: 55px;
    }

    .delete-button {
        width: 178px;
        height: 63px;
        background: #00A5FF;
        border-radius: 10px;
        color: #fff;
        @include font-params (700, 18px, 25px, 0.05em);
        margin-right: 46px;
    }

    &.private-area {
        cursor: pointer;

        .fishing-place__cart {
            cursor: default;
        }
    }


}

.map-pop-up {
    display: block;
    position: absolute;
    width: 500px;
    height: 500px;
    top: 0;
    z-index: 10;
    border-radius: 10px;
    transform: scale(0);
    transition: transform 0.5s;

    .map-pop-up__close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        background: url('../../../../public/images/icons/close-button.svg') no-repeat center;
        background-size: contain;
    }

    &.open {
        transform: scale(1);
    }

    .ymaps-2-1-79-map {
        width: 100% !important;
        height: 100% !important;
        border-radius: 20px;

        .ymaps-2-1-79-inner-panes {
            border-radius: 20px;
            box-shadow: 0 0 5px 3px rgba($color: #000000, $alpha: .5);
        }
    }
}

.slick-dots {
    display: none !important;
}