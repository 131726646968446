@media only screen and (max-width: 1320px) and (min-width: 1024px) {
    main {
        .main__title-wrapper {
            background: url('../../../../../public/images/main/main-laptop-bg.png') no-repeat center;
            background-size: cover;

            .main__title {
                padding: 217px 0 211px;
                @include block-pos-laptop;

                p {
                    max-width: 525px;
                }

                >div {
                    width: 769px;

                    input {
                        width: 769px;
                    }
                }
            }
        }

        .main__fishing-places {
            padding: 20px 0 46px;
            @include block-pos-laptop;

            .fishing-places__title {
                margin-bottom: 16px;

                h2 {
                    max-width: 347px;
                    margin-right: 121px;
                }
            }
        }
    }
}