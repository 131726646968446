@media only screen and (max-width: 1320px) and (min-width: 1024px) {
    .fishing-place__cart {
        @include flex-params (row, space-between, top);
        padding: 15px;
        border: 1px solid #CBD2E0;
        border-radius: 10px;
        margin-bottom: 10px;
        color: $link_color;
        width: 100%;
        height: 258px;

        &.active {
            flex-wrap: wrap;
            height: max-content;

            >div {
                &.fishing-place__pic {
                    margin-bottom: 20px;
                }

                &.fishing-place__desc {
                    max-width: 457px;
                    margin-bottom: 20px;

                    .desc_footer {
                        @include flex-params (row, space-between, center);

                        .likes-count {
                            @include font-params (900, 22px, 25px, 0.05em);

                            &::before {
                                width: 32px;
                                height: 32px;
                                background-size: cover;
                            }
                        }
                    }
                }

                &.fishing-place__types {
                    margin: 0;
                    margin-bottom: 20px;
                    max-width: 100%;
                }

                &.fishing-place__details {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-rows: 66px;
                    max-width: 100%;

                    a {
                        margin-left: 7px;
                        grid-column: 4;
                        grid-row: 1;
                    }
                }
            }
        }

        >div {
            &.fishing-place__desc {
                max-width: 270px;

                p {
                    line-clamp: 5;
                    -webkit-line-clamp: 5;
                }
            }

            &.fishing-place__types {
                max-width: 287px;
            }
        }
    }

    .fishing-place__wrapper {
        &:last-child {
            margin-bottom: 23px;
        }
    }
}