* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}


html, body, #root, .container {
    height: 100%;
    display: grid;
}

.container {
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

footer {
    flex: 0 0 auto;
}

h1,
h2 {
    font-family: 'Days One', sans-serif;

    span {
        font-family: 'Days One', sans-serif;

    }
}

a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    color: $link_color;
}

ul {
    list-style-type: none;
}

button {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
}

input {
    outline: none;
}