@import 'https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap';

@font-face {
    font-family: 'Days One';
    src: url('../fonts/days-one.regular.ttf') format('ttf'),
        url('../fonts/days-one.regular.woff2') format('woff2'),
        url('../fonts/DaysOne-Regular.woff') format('woff');
}

@import 'abstracts/variables';
@import 'base/reset';
@import 'layout/header';
@import 'layout/main';
@import 'layout/components/place-cart';
@import 'layout/components/fishing-place-cart';
@import 'layout/components/comments';
@import 'layout/components/subscription';
@import 'layout/components/contact';
@import 'layout/ads-block';
@import 'layout/footer';

@import 'layout/components/authorization/registration-type';
@import 'layout/components/authorization/registration-page';
@import 'layout/components/authorization/authorization-page';
@import 'layout/components/private-area/private-area';
@import 'layout/components/private-area/delete-pop-up';

@import 'layout/adaptive/laptop/header';
@import 'layout/adaptive/laptop/main';
@import 'layout/adaptive/laptop/components/place-cart';
@import 'layout/adaptive/laptop/components/fishing-place-cart';
@import 'layout/adaptive/laptop/footer';

@import 'layout/adaptive/laptop/components/private-area/private-area';


@import 'layout/adaptive/mobile/header';
@import 'layout/adaptive/mobile/main';
@import 'layout/adaptive/mobile/components/place-cart';
@import 'layout/adaptive/mobile/components/calendar-mobile';
@import 'layout/adaptive/mobile/components/fishing-place-cart';
@import 'layout/adaptive/mobile/components/subscription';
@import 'layout/adaptive/mobile/components/contact';
@import 'layout/adaptive/mobile/footer';

@import 'layout/adaptive/mobile/components/authorization/registration-type';
@import 'layout/adaptive/mobile/components/authorization/registration-page';
@import 'layout/adaptive/mobile/components/authorization/authorization-page';
@import 'layout/adaptive/mobile/components/private-area/private-area';
@import 'layout/adaptive/mobile/components/private-area/delete-pop-up';