.comments__wrapper {
    display: none !important;
    padding: 43px 0 107px;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 917px;
    height: 600px;
    border-radius: 8px;
    z-index: 15;
    padding: 32px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.45);
    background: #EDF0F7;

    &.show {
        display: flex !important;
        @include flex-params (column, flex-start, center);
        overflow: hidden;

    }

    .overlay {
        width: 853px;
        padding: 0;

        form {
            background-color: #fff;
        }

        .comment-title, hr, .no-comDiv, .emoji-icon {
            display: none;
        }
    }

    .comments_title {
        display: flex;
        position: relative;
        margin-bottom: 33px;

        h2 {
            @include font-params (400, 40px, 51px, 0.016em);
            color: $link_color;
        }

        >div {
            position: absolute;
            display: flex;
            left: 432px;
            top: 12px;
            color: $link_color;

            .filter-arrow {
                width: 24px;
                height: 24px;
                background: url('../../../../public/images/main/icon-filter-arrow-cart.svg') no-repeat center;
                margin-right: 10px;

                &.down {
                    transform: rotate(180deg);
                }
            }

            p {
                font-family: 'Days One', sans-serif;
                @include font-params (400, 18px, 23px, 0.016em);
                color: $link_color;
                text-decoration: underline;
                width: 82px;
            }
        }
    }
}