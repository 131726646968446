@media only screen and (max-width: 1320px) and (min-width: 1024px) {
    .place-cart__wrapper {
        section {
            @include block-pos-laptop;
            flex-direction: column;

            .place-cart__photo {
                @include flex-params (row, flex-start, center);
                margin-right: 0;
                margin-bottom: 31px;

                img {
                    width: 505px;
                    height: 322px;
                    margin-bottom: 0;
                    margin-right: 25px;

                    &:last-child {
                        width: 504px;
                        margin: 0;
                    }
                }
            }

            .place-cart__description {
                @include flex-params (column, flex-start, left);
                position: relative;

                .statistic {
                    @include flex-params (row, flex-start, center);
                    margin-bottom: 35px;

                    >div {
                        margin-right: 28px;
                    }
                }

                .place-cart__footer {
                    @include flex-params (row, space-between, center);

                    >button {
                        position: absolute;
                        top: 58px;
                        right: 0;
                    }
                }
            }
        }

        .place-cart__terms {
            >div {
                >div {
                    &:nth-child(2) {
                        p {
                            width: 208px;
                        }
                    }
                }
            }
        }

        .place-cart__rates {
            flex-direction: column;
            align-items: center;
            padding: 43px 0 37px;
            color: #221F63;

            h2 {
                @include font-params (400, 40px, 51px, 0.016em);
                margin-bottom: 44px;
            }

            >div {
                @include flex-params (row, space-between, flex-start);

                div {
                    padding: 24.5px 20px;
                    font-family: 'Days One', sans-serif;
                    background: #EDF0F7;
                    border-radius: 10px;
                    margin-right: 17px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:nth-child(2) {
                        h3 {
                            width: 384px;
                        }
                    }


                    h3 {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 20px, 25px, 0.016em);
                        width: 248px;
                        color: #00A5FF;
                        margin-bottom: 10px;
                    }

                    button {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 16px, 21px, 0.05em);
                        color: $link_color;

                        &::after {
                            content: '';
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            vertical-align: middle;
                            transform: rotate(180deg);
                            margin-left: 5px;
                        }
                    }

                    ul {

                        li {
                            color: $link_color;
                            @include font-params (400, 16px, 22px, 0.05em);

                            &:last-child {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }

        .place-cart__stocking {
            @include flex-params (column, flex-start, center);
            color: $link_color;

            h2 {
                margin-bottom: 43px;
                @include font-params (400, 40px, 51px, 0.016em);
            }

            >div {
                display: flex;
                flex-direction: column;
                width: 100%;

                .stocking_table {
                    @include flex-params (column, flex-start, center);

                    table {
                        width: 100%;
                        height: 226px;
                        border-collapse: collapse;
                        margin-bottom: 20px;

                        tr:nth-child(even) {
                            background-color: #E2E7F0;
                        }

                        thead {
                            tr {
                                background-color: #E2E7F0;

                                th {
                                    height: 34px;
                                    text-align: left;
                                    padding-left: 10px;

                                    &:nth-child(3) {
                                        text-align: right;
                                        padding-right: 45px;
                                    }

                                    &:nth-child(5) {
                                        text-align: right;
                                    }

                                    &:nth-child(6) {
                                        text-align: right;
                                        padding-right: 10px;
                                    }

                                    &::after {
                                        content: '';
                                        display: inline-block;
                                        width: 24px;
                                        height: 24px;
                                        transform: rotate(180deg);
                                        vertical-align: middle;
                                    }

                                    button {
                                        color: $link_color;
                                        font-family: 'Days One', sans-serif;
                                        @include font-params (400, 16px, 22px, 0.016em);
                                    }
                                }
                            }
                        }

                        tbody {

                            td {
                                padding-left: 10px;
                                height: 32px;
                                @include font-params (400, 16px, 22px, 0.05em);

                                &:nth-child(1) {
                                    text-align: left;
                                    width: 130px;
                                }

                                &:nth-child(2) {
                                    width: 65px;
                                }

                                &:nth-child(3) {
                                    text-align: right;
                                    padding-right: 50px;
                                    width: 240px;
                                }

                                &:nth-child(4) {
                                    width: 140px;
                                }

                                &:nth-child(5) {
                                    text-align: right;
                                    width: 120px;
                                }

                                &:nth-child(6) {
                                    text-align: right;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }

                .add-terms {
                    padding: 51.5px 20px;
                }
            }
        }

        .place-cart__reviews {
            flex-direction: column;
            padding: 45px 0 73px;

            .reviews_title {
                @include flex-params (row, space-between, center);
                margin-bottom: 34px;

                div {
                    @include flex-params (row, flex-start, center);

                    .filter-arrow {
                        width: 24px;
                        height: 24px;
                        margin-right: 10px;

                        &.down {
                            transform: rotate(180deg);
                        }
                    }

                    p {
                        font-family: 'Days One', sans-serif;
                        @include font-params (400, 18px, 23px, 0.016em);
                        color: #1A1A1A;
                        text-decoration: underline;
                    }
                }

                h2 {
                    @include font-params (400, 40px, 51px, 0.016em);
                    color: $link_color;
                }

                >button {
                    background-color: #00A5FF;
                }
            }

            .reviews__wrapper {
                flex-wrap: wrap;
                @include flex-params (row, space-between, center);
                margin-bottom: 10px;

                .review__container {
                    display: flex;
                    max-width: 500px;
                    max-height: 182px;
                    margin-bottom: 48px;

                    .user-photo {
                        margin-right: 20px;

                        img {
                            width: 144px;
                            height: 182px;
                            object-fit: cover;
                        }
                    }

                    .review-text {
                        margin: 4px 0 10px;
                    }

                    p {
                        @include font-params (400, 16px, 22px, 0.05em);
                        color: #1A1A1A;

                        &.user-name {
                            font-family: 'Days One', sans-serif;
                            @include font-params (400, 20px, 25px, 0.016em);
                            margin-bottom: 12px;

                            span {
                                @include font-params (400, 20px, 27px, 0.016em);
                                margin-left: 10px;
                            }
                        }
                    }

                    .details__footer {
                        display: flex;
                        justify-content: space-between;

                        >div {
                            display: flex;

                            .likes-count {
                                @include font-params (900, 16px, 22px, 0.05em);
                                margin-right: 10px;
                                color: $link_color;

                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                }
                            }

                            .comment-count {
                                @include font-params (900, 16px, 22px, 0.05em);
                                color: $link_color;
                                text-decoration: none;

                                &::before {
                                    content: '';
                                    display: inline-block;
                                    width: 24px;
                                    height: 24px;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                }
                            }
                        }

                        >p {
                            @include font-params (900, 16px, 22px, 0.05em);
                            text-decoration: underline;
                        }
                    }
                }
            }

            .show-more {
                height: 63px;
                width: 205px;
                margin: auto;
                background: #FC8000;
                border-radius: 10px;
                color: #fff;
                @include font-params (700, 18px, 25px, 0.05em);
            }
        }
    }
}