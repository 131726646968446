.ads-block {
    height: 180px;
    background-color: #00D2D0;
    max-width: 100%;
    display: flex;

    h2 {
        text-align: center !important;
        margin: auto;
        @include font-params (400, 48px, 65px, 0.05em);
    }
}

@media only screen and (max-width: 1023px) and (min-width: 311px) {
    .ads-block {
        height: 133px;

        h2 {
            @include font-params (400, 34px, 65px, 0.05em);
            font-family: 'Nunito Sans', sans-serif;
            padding-top: 34px;
        }
    }
}