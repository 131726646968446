header {

    .header__mail-row {
        background: #DB0BC0;
        color: #fff;

        a {
            @include block-pos;
            @include flex-params (row, right, center);
            @include font-params (700, 20px, 150%, 0.05em);
            color: #fff;

            &::before {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                background: url('../../../public/images/header/icon-mail.svg') center no-repeat;
                margin-right: 5px;
            }
        }
    }

    .header__nav {
        @include block-pos;
        @include flex-params (row, space-between, center);
        padding: 24px 0 32px;

        .logo {
            display: flex;

            img {
                width: 74px;
                margin-right: 10px;
            }

            h2 {
                color: #204BDB;
                @include font-params (400, 40px, 120%, 0.01em);

                span {
                    color: #DB0BC0;
                }
            }
        }

        nav {
            ul {
                display: flex;

                li {
                    margin-right: 30px;

                    a {
                        @include font-params (700, 24px, 36px, 0.05em);

                        &:hover {
                            color: #DB0BC0;
                            border-bottom: #DB0BC0 2px solid;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .private-area__button {
            @include font-params (700, 24px, 36px, 0.05em);
            background-color: #204BDB;
            color: #fff;
            border-radius: 10px;
            padding: 14px 30px 13px;
        }

        .header_buttons {
            display: flex;

            a {
                @include font-params (700, 18px, 25px, 0.05em);
                letter-spacing: 0.05em;
                color: #221F63;
                border-radius: 10px;
                display: block;
                height: 63px;

                &.authorization {
                    border: 1px solid #221F63;
                    margin-right: 19px;
                    padding: 19px 48.5px 0;
                }

                &.registration {
                    background-color: #204BDB;
                    color: #fff;
                    padding: 19px 32px 0;
                }
            }
        }
    }

    .hamburger-button {
        display: none;  
    }

    .hamburger-menu {
        display: none;
    }
}